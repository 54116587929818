<template>
  <v-card v-bind="$attrs" v-on="$listeners" :loading="loading">
    <v-card-title class="headline">Pending</v-card-title>
    <v-card-subtitle>
      <span
        v-if="
          this.selectedFiscalYear && this.selectedFiscalYear.fiscalYearStart && this.selectedFiscalYear.fiscalYearEnd
        "
        ><b
          >({{ this.selectedFiscalYear.fiscalYearStart | formatDateClient("MM/DD/YYYY", selectedClient) }} -
          {{ this.selectedFiscalYear.fiscalYearEnd | formatDateClient("MM/DD/YYYY", selectedClient) }})</b
        ><br
      /></span>
      <span>
        This amount is pending payment to you!
      </span>
    </v-card-subtitle>
    <v-card-text class="display-2 text-center" v-if="resultTotal">
      {{ resultTotal.calculatedAmount | toNumber(2, selectedProgram) }}
      {{ resultTotal.calculatedAmountCurrency ? resultTotal.calculatedAmountCurrency : "" }}
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CompanyPendingPaymentWidget",
  data() {
    return {
      loading: true,
      result1: null,
      result2: null,
      resultTotal: null
    };
  },
  methods: {
    getData() {
      this.loading = true;
      this.getPendingClaimAmount()
        .then(() => {
          this.getPendingClaimPaidAmount().then(() => {
            this.resultTotal = {
              amount: this.result1.amount + this.result2.amount,
              currency: this.result1.currency,
              calculatedAmount: this.result1.calculatedAmount + this.result2.calculatedAmount,
              calculatedAmountCurrency: this.result1.calculatedAmountCurrency
            };
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getPendingClaimAmount() {
      let filters = {
        submitted: true,
        closed: false,
        approved: true,
        organization: { id: this.selectedParticipant.organization.id },
        createdAfter: this.selectedFiscalYear
          ? this.selectedFiscalYear.fiscalYearStart
          : this.selectedFiscalYear.fiscalYearStart,
        createdBefore: this.selectedFiscalYear
          ? this.selectedFiscalYear.fiscalYearEnd
          : this.selectedFiscalYear.fiscalYearEnd
      };
      return this.$api
        .post("/api/claims/search/sumCalculatedTotalClaimAmountForOrganizations", filters)
        .then(({ data }) => {
          this.result1 = data;
        });
    },
    getPendingClaimPaidAmount() {
      let filters = {
        submitted: true,
        closed: false,
        approved: true,
        organization: { id: this.selectedParticipant.organization.id },
        createdAfter: this.selectedFiscalYear.fiscalYearStart,
        createdBefore: this.selectedFiscalYear.fiscalYearEnd
      };
      return this.$api
        .post("/api/claims/search/sumCalculatedTotalPaidAmountForOrganization", filters)
        .then(({ data }) => {
          this.result2 = data;
        });
    }
  },
  watch: {
    selectedFiscalYear() {
      this.getData();
    }
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedClient", "selectedProgram", "selectedFiscalYear"])
  }
};
</script>
